import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { CookieService } from '@sbt-suite/components';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    URL_LOGIN = environment.URL_LOGIN;
    private apisRequiringAuth = ['/api/v1/aplicacoes'];

    constructor(private _cookie: CookieService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this._cookie.get(environment.ACCESS_TOKEN);
        const isUrlAppToken =
            request.url.includes('/login/api/v1/login') || request.url.includes('/api/v1/login');

        const requiresAuth = this.apisRequiringAuth.some((apiUrl: string) =>
            request.url.includes(apiUrl)
        );

        if (!token && requiresAuth) {
            window.location.href = this.URL_LOGIN;
            return EMPTY;
        } else if (token && !isUrlAppToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }
}
