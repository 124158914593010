import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    AUTH_INTERCEPTOR_CONFIG,
    CopyrightModule,
    CORE_CONFIG_TOKEN,
    LOCAL_STORAGE_CONFIG_TOKEN,
    MenuLateralModule,
    NavBarModule,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        NavBarModule,
        MenuLateralModule,
        CopyrightModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: AUTH_INTERCEPTOR_CONFIG,
            useValue: {
                urlLogin: environment.URL_LOGIN
            }
        },
        {
            provide: CORE_CONFIG_TOKEN,
            useValue: {
                URL_BASE: environment.APP_URL_LOGIN,
                URL_LOGIN: environment.URL_LOGIN,
                accessToken: environment.ACCESS_TOKEN,
                refreshToken: environment.REFRESH_TOKEN
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: PERMISSOES_SERVICE_TOKEN,
            useClass: PermissoesService
        },
        {
            provide: LOCAL_STORAGE_CONFIG_TOKEN,
            useValue: environment
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
