import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertMessageService, CookieService } from '@sbt-suite/components';
import {
    BehaviorSubject,
    Observable,
    catchError,
    filter,
    finalize,
    switchMap,
    take,
    throwError
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResponseAuthLogin } from '../models/usuario.model';
import { UsuariosService } from '../services/usuarios.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    URL_LOGIN = environment.URL_LOGIN;
    isGetToken: boolean = false;
    tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private alertMessage: AlertMessageService,
        private _cookie: CookieService,
        private _usuarioService: UsuariosService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && error.error.message === 'Refresh Token Expirado') {
                    this.showAlert(error);
                    this._redirectLogin();
                }

                if (this.isGetToken) {
                    return this.tokenSubject.pipe(
                        filter((_token) => _token),
                        take(1),
                        switchMap((newToken) => {
                            const req = this._addTokenToHeader(request, newToken);
                            return next.handle(req);
                        })
                    );
                } else if (error.status === 401) {
                    if (error.error.message === 'Token expirado') {
                        this.isGetToken = true;
                        this.tokenSubject.next(null);
                        const token = this._cookie.get(environment.REFRESH_TOKEN);
                        return this.handleTokenRefresh(request, next, token);
                    } else if (!this.isGetToken) {
                        this.showAlert(error);
                        this._redirectLogin();
                    }
                }

                return throwError(() => error);
            })
        );
    }

    showAlert(error: HttpErrorResponse) {
        this.alertMessage.mostrarAlerta(`${error?.error?.message || error?.message}`);
    }

    private _redirectLogin() {
        localStorage.clear();
        window.location.href = this.URL_LOGIN;
    }

    private handleTokenRefresh(
        request: HttpRequest<any>,
        next: HttpHandler,
        token: string
    ): Observable<HttpEvent<any>> {
        return this._usuarioService.refreshToken(token).pipe(
            switchMap((response: IResponseAuthLogin) => {
                this._usuarioService.setTokenUsuario(response.access_token, response.refresh_token);

                this.tokenSubject.next(response.access_token);
                const req = this._addTokenToHeader(request, response.access_token);
                return next.handle(req);
            }),
            catchError((error: any) => {
                return throwError(() => error);
            }),
            finalize(() => (this.isGetToken = false))
        );
    }

    private _addTokenToHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
        const _req = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
        return _req;
    }
}
